.wrapper {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 100px 24px;

  h4 {
    font-weight: 800;
    font-size: 24px;
    text-align: center;
  }

  p {
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
}
