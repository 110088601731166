.root {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;

  & * {
    user-select: none;
  }
}

.close {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 9px;
  right: 16px;
  cursor: pointer;

  path {
    stroke: #c7c7c7;
  }
}

.title {
  color: #000;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 40px;
  text-align: center;
  letter-spacing: -1px;
}

.content {
  width: 100%;
  border-radius: 24px;
  background: #ffffff;
  padding: 35px 24px;
  margin-bottom: 10px;
}

.button {
  width: 100%;
  height: 60px;
  margin-bottom: 24px;

  span {
    font-size: 14px;
    font-weight: 800;
    color: #000;
    text-transform: uppercase;

    &:last-child {
      font-style: italic;
    }
  }
}
