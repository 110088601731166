.root {
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.2s ease;
  font-style: italic;
  padding: 8px 16px;

  .center {
    font-size: 22px;
    font-weight: 800;
    line-height: 24px;
    color: #fff;
    text-transform: capitalize;
    width: 90%;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 10px;
    gap: 8px;
  }

  .visit_profile {
    padding: 6px 0px;
    color: #fff;
    text-transform: capitalize;
  }

  .titleText {
    font-size: 22px;
    font-weight: 800;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  .left,
  .right {
    width: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & svg {
      filter: drop-shadow(0 0 5px rgb(0, 0, 0));
    }

    &:hover {
      & svg {
        opacity: 0.8;
      }
    }
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
  }
}
