.root {
  animation: 1s loading-skeleton cubic-bezier(0.04, 0.81, 0.5, 0.94) infinite;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      100deg,
      rgb(238 238 238 / 0%) 40%,
      rgb(217 217 217 / 10%) 50%,
      rgb(238 238 238 / 0%) 60%
    )
    #262626;
  background-size: 200% 100%;
  background-position-x: 180%;

  &.light {
    animation: 1s loading-skeleton cubic-bezier(0.04, 0.81, 0.5, 0.94) infinite;
    background: linear-gradient(
        100deg,
        rgb(238 238 238 / 3%) 40%,
        rgb(217 217 217 / 30%) 50%,
        rgb(238 238 238 / 3%) 60%
      )
      #dfdfdf;
    background-size: 200% 100%;
    background-position-x: 180%;
  }
}

@keyframes loading-skeleton {
  to {
    background-position-x: -20%;
  }
}
