$avatar-l: 84px;

.wrapper {
  width: 100%;

  & h2 {
    margin-top: 24px;
    font-weight: 800;
    font-size: 22px;
    text-align: center;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  padding-top: 24px;

  .icon {
    width: 60px;
    height: 60px;
  }
}

.content {

  .user {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    @media screen and (max-height: 750px) {
      margin-bottom: 20px;
    }

    @media screen and (max-height: 600px) {
      margin-bottom: 8px;
    }

    &_avatar {
      width: $avatar-l;
      height: $avatar-l;
      position: relative;
      display: flex;
      margin-bottom: 16px;

      & img {
        border-radius: calc($avatar-l / 2);
        overflow: hidden;
        width: $avatar-l;
        height: $avatar-l;
      }

      & svg {
        width: $avatar-l;
        height: $avatar-l;
        border-radius: calc($avatar-l / 2);
      }
    }

    &_status {
      width: 20px;
      height: 20px;
      border: 3px solid #000000;
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #5DFE35;
      z-index: 5;
    }

    &_name {
      height: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;

      & > p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        height: 30px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
      }
    }
  }

  .messages {
    &_item {
      width: fit-content;
      min-height: 44px;
      margin-bottom: 8px;
      border-radius: 22px;
      background: #1C1C1E;
      display: flex;
      align-items: center;
      padding: 8px 16px 10px;
    }
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 80px;
  padding-bottom: 54px;
}

.buttonText {
  font-weight: 800;
  text-transform: none;

  & > span {
    font-weight: 800;
    text-transform: none;
    letter-spacing: -2px;
  }
}
