.root {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;

  &_item {
    width: 100%;
    height: 60px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    & span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.item {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;

  & span {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}
