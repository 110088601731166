.root {
  width: 100%;
  height: 100%;

  label {
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-bottom: 22px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0 18px 0 0;

    width: 24px;
    height: 24px;
    border: 7px solid #292d32;
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]:checked {
    border: 7px solid #fff;
  }
}

.area {
  max-height: calc(100% - 140px);

  @media screen and (max-height: 550px) {
    max-height: calc(100% - 125px);
  }
}

.content {
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 16px 24px 16px;
}

.field {
  margin-bottom: 18px;

  &_title {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    margin-bottom: 16px;
    color: #fff;

    & > span {
      color: #ff4848;
    }
  }

  &_input {
    margin-bottom: 8px;
  }

  &_link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
    color: #4285f4;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
}

.step {
  height: 32px;
  color: #000;
  background: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 14px;
}
