.root {
  position: absolute;
  width: calc(100% - 16px);
  height: fit-content;
  min-height: 178px;
  bottom: 4px;
  left: 8px;
  right: 8px;
  padding: 16px;
  z-index: 15;
  border-radius: 24px;
  background: linear-gradient(90deg, #232526 0%, #414345 100%);
  pointer-events: auto;
}

.content {
  max-width: 310px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;

  &_text {
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }

  &_button {
    color: #ffffff;
    margin: 0 auto;
    font-weight: 800;
    font-size: 12px;
    width: 100%;
    max-width: 200px;
  }
}
