.root {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-sizing: content-box;

  &.bordered {
    .image {
      border: 2px solid #fff;
    }
  }

  .insta_badge {
    position: absolute;
    top: 0;
    right: -2px;
  }

  .online {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 16px;
    height: 16px;
    border: 3px solid #000;
    border-radius: 50%;
    background: #5dfe35;
  }

  .online_feed {
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #5dfe35;
  }

  .last_seen {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 16px;
    border: 2px solid #000;
    color: #000;
    border-radius: 10px;
    background: #ff9914;
    font-size: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
  }

  .last_seen_badge {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 16px;
    height: 16px;
    border: 2px solid #000;
    color: #000;
    border-radius: 100%;
    background: #ff9914;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #000;
    }
  }

  .last_seen_badge_feed {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 100%;
    background: #ff9914;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #fff;
    }
  }

  .image {
    overflow: hidden;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    align-items: stretch;
    justify-content: center;

    & img {
      width: 100%;
    }

    & svg {
      width: 48px;
      height: 48px;
    }
  }

  &.feed {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;

    .image {
      width: 40px;
      height: 40px;
      border-radius: 20px;

      & svg {
        width: 40px;
        height: 40px;
      }
    }

    &.bordered {
      .image {
        border: 1px solid #fff;
      }
    }

    .online {
      right: -1px;
      bottom: -1px;
      width: 12px;
      height: 12px;
      border-width: 2px;
    }

    .last_seen_badge {
      width: 12px;
      height: 12px;

      &:after {
        width: 3px;
        height: 3px;
      }
    }
  }

  &.small {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 16px;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 16px;

      & svg {
        width: 32px;
        height: 32px;
      }
    }

    &.bordered {
      .image {
        border: 1px solid #fff;
      }
    }

    .online {
      right: -1px;
      bottom: -1px;
      width: 10px;
      height: 10px;
      border-width: 2px;
    }
  }

  &.normal {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 16px;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 16px;

      & svg {
        width: 32px;
        height: 32px;
      }
    }

    &.bordered {
      .image {
        border: 1px solid #fff;
      }
    }

    .online {
      right: -1px;
      bottom: -1px;
      width: 10px;
      height: 10px;
      border-width: 2px;
    }
  }

  &.medium {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 30px;

    .image {
      width: 60px;
      height: 60px;
      border-radius: 30px;

      & svg {
        width: 60px;
        height: 60px;
      }
    }

    &.bordered {
      .image {
        border: 1px solid #fff;
      }
    }

    .online {
      right: 0px;
      bottom: 0px;
      width: 16px;
      height: 16px;
      border-width: 2px;
    }
  }

  &.large {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 40px;

    .image {
      width: 80px;
      height: 80px;
      border-radius: 40px;

      & svg {
        width: 80px;
        height: 80px;
      }
    }

    &.bordered {
      .image {
        border: 2px solid #fff;
      }
    }

    .online {
      right: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      border-width: 4px;
    }
  }

  &.extra_large {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;

    .image {
      width: 100px;
      height: 100px;
      border-radius: 100%;

      & svg {
        width: 100px;
        height: 100px;
      }
    }

    &.bordered {
      .image {
        border: 2px solid #fff;
      }
    }

    .online {
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      border-width: 4px;
    }
  }
}
