.root {
  position: relative;
  height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-height: 550px) {
    height: 40px !important;
  }

  .title {
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }

  .icon {
    position: relative;
    max-width: 24px;
    max-height: 24px;

    &_end {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 24px;
      max-height: 24px;
    }

    &_start {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 24px;
      max-height: 24px;
    }
  }

  &.white {
    &.outlined {
      background: transparent;
      border: 1px solid #fff;

      & span {
        color: #fff;
      }
    }

    &.filled {
      background: #fff;
    }
  }

  &.gold {
    &.outlined {
      background: transparent;
      border: 1px solid #d7fb73;

      & span {
        color: #fff;
      }
    }

    &.filled {
      background: #d7fb73;
    }
  }

  &.pink {
    &.outlined {
      background: transparent;
      border: 1px solid #ff1694;

      & span {
        color: #fff;
      }
    }

    &.filled {
      background: #ff1694;
    }
  }

  &.auth {
    background-color: #fff;

    & .icon {
      position: absolute;
      top: 0;
      left: 20px;
      bottom: 0;
      margin: auto;
    }

    & span {
      color: #000;
      text-transform: none;
      font-style: normal;
    }
  }

  &.text {
    border: none;
    background-color: transparent;

    & span {
      color: #000;
      text-transform: none;
      font-style: normal;
      font-weight: 600;
    }
  }

  &.disabled {
    background: #747474 !important;
    cursor: default;

    &.outlined {
      background: transparent !important;
    }

    & span {
      color: #474747;
    }

    & .icon_end path,
    & .icon_start path {
      filter: brightness(0.5);
    }
  }
}
