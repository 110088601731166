.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.error_base {
  position: fixed;
  top: -1000px;
  left: 0;
  width: 100%;
  max-width: 420px;
  right: 0;
  margin: auto;
  min-height: 100px;
  background: #ff4848;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  padding: 24px 48px 24px 24px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  transition: top 0.2s ease;
  z-index: 10;

  @media screen and (max-height: 650px) {
    font-size: 14px;
    min-height: 80px;
  }

  & > span {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  & svg {
    cursor: pointer;
  }
}

.error {
  @extend .error_base;
}

.error_incorrect_pass {
  @extend .error_base;
  flex-direction: column;
}

.visible {
  top: 0;
  transition: top 0.2s ease;
}

.field {
  margin-bottom: 10px;
  height: 50px;

  @media screen and (max-height: 650px) {
    height: 40px;
  }

  & > input {
    height: 50px;

    @media screen and (max-height: 650px) {
      height: 40px;
    }
  }
}

.close {
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.auth {
  width: 100%;
  margin: 20px 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;

  @media screen and (max-height: 750px) {
    margin: 16px 0;
  }

  @media screen and (max-height: 650px) {
    margin: 8px 0;
  }

  &_active {
    max-height: 500px;
    opacity: 1;
    transition: max-height 0.3s ease;
  }

  &_content {
    width: 100%;
    height: 100%;
    max-height: 830px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
    margin-bottom: 32px;

    &_join {
      margin-bottom: 32px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 800;
      text-align: center;
      padding: 0 24px;
    }

    &_video,
    &_comment,
    &_like,
    &_follow,
    &_vote {
      max-width: 100%;
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 800;
      text-align: center;
      padding: 0 12px;
      box-sizing: border-box;

      &_name {
        display: inline-block;
        font-size: 14px;
        vertical-align: top;
        font-weight: 800;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }
    }

    &_video {
      font-size: 16px;
    }

    @media screen and (max-height: 650px) {
      margin-bottom: 16px;
    }

    & > span {
      color: #ffffff;
      font-size: 28px;
      font-weight: 800;
    }

    & > span:last-child {
      color: #ff1694;
      font-style: italic;
    }
  }

  &_features {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;

    @media screen and (max-height: 650px) {
      margin-bottom: 16px;
    }

    &_item {
      display: flex;
      gap: 8px;
      font-size: 14px;
      font-weight: 800;
      align-items: center;
    }
  }

  &_socials {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-height: 650px) {
      gap: 8px;
    }
  }

  &_text {
    width: 100%;
    text-align: center;
    margin: 16px 0;
    font-size: 12px;

    @media screen and (max-height: 650px) {
      margin: 8px 0;
    }
  }

  &_form {
    width: 100%;
  }

  &_dynamic {
    height: fit-content;
    overflow: hidden;
  }

  &_links {
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    color: #747474;
    margin: 20px 0;
  }

  &_navigation {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
  }

  &_last {
    margin: 0;
  }
}
