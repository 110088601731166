.root {
  //width: 40px;
  //height: 40px;
  //position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  z-index: 12;
  will-change: transform;
  transform: translateZ(0);

  & * {
    will-change: transform;
    transform: translateZ(0);
  }
}
