.root {
  position: absolute;
  width: 100%;
  top: 24px;
  left: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  will-change: transform;
  transform: translateZ(0);

  & * {
    will-change: transform;
    transform: translateZ(0);
  }
}

.challenge {
  width: fit-content;
  //left: 16px;
  //bottom: 24px;
  cursor: pointer;
  padding: 0 12px 0 0;
  background: #fff;
  height: 24px;
  line-height: 32px;
  border-radius: 16px;
  pointer-events: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  will-change: transform;
  transform: translateZ(0);

  & * {
    will-change: transform;
    transform: translateZ(0);
  }

  &_place {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 0 12px;
    margin-left: -1px;
  }

  &_name,
  &_place {
    letter-spacing: -0.5px;
  }

  &_name,
  &_place {
    line-height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    white-space: nowrap;

    & > div {
      gap: 0;

      & svg {
        margin-right: 3px;
      }
    }
  }

  &_name {
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  filter: drop-shadow(0 0 5px rgb(0, 0, 0));
}
