.root {
  height: 100%;
  position: relative;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  & > img {
    width: 100%;
    height: auto;
  }

  &_gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &_blur {
    background: rgb(0 0 0 / 80%);
    backdrop-filter: blur(5px);

    & > img {
      display: none;
    }
  }
}

.content {
  height: 100%;
  position: relative;
  z-index: 3;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  & .navigation {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 12px;
    text-align: center;
  }
}
