.root {
  width: 100%;
  min-height: 100%;
  position: relative;
  max-width: 420px;
  margin: auto;
  overflow: hidden;
  border-radius: 24px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}

.root *:not(g) {
  user-select: none;
  //will-change: transform;
  //transform: translateZ(0);
}

.link_message {
  cursor: pointer;
  text-decoration: underline;
}

.video {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  //min-height: max-content;
  //max-height: 150%;
  border-radius: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  outline: none;
  /* object-fit: fill; */
  /* overflow-clip-margin: content-box; */
  /* overflow: auto; */
  /* position: relative; */
  z-index: 1;
  object-fit: contain;
  border: none;

  will-change: transform;
  transform: translateZ(0);
}

video {
  will-change: transform;
  transform: translateZ(0);
}

.video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.video_overlay.loading {
  animation: 1s loading-skeleton cubic-bezier(0.04, 0.81, 0.5, 0.94) infinite;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      100deg,
      rgb(238 238 238 / 0%) 40%,
      rgb(217 217 217 / 10%) 50%,
      rgb(238 238 238 / 0%) 60%
    )
    #26262600;
  background-size: 200% 100%;
  background-position-x: 180%;
}

.video_overlay.paused {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //background: rgba(0, 0, 0, .4);
  will-change: transform;
  transform: translateZ(0);

  & svg {
    width: 70px;
    height: 70px;
  }
}

.mute {
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  z-index: 12;
  will-change: transform;
  transform: translateZ(0);
}

.player {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./../../assets/images/player.png");
  border: 0;
  height: 62px;
  inset: 0;
  margin: auto;
  position: absolute;
  width: 62px;
  z-index: 5;
}

.back {
  position: absolute;
  bottom: 24px;
  left: 10px;
  width: calc(100% - 20px);
  height: 60px;
  //background: #D7FB73;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  color: #000;
  z-index: 6;
}

.progress {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 12;
  will-change: transform;
  transform: translateZ(0);

  & * {
    will-change: transform;
    transform: translateZ(0);
  }

  &_indicator {
    position: absolute;
    height: 2px;
    width: 0;
    //left: -100%;
    left: 0;
    top: 0;
    background: #fff;
    border-radius: 2px;
  }
}

@keyframes loading-skeleton {
  to {
    background-position-x: -20%;
  }
}
