.root {
  background-color: #000;
  height: calc(100% - var(--sat));
  min-height: calc(100% - var(--sat));
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: var(--sat);
  left: 0;
  right: 0;
  bottom: -200%;
  z-index: 0;
  max-width: 422px;
  margin: auto;
  transition: bottom 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  & * {
    user-select: none;
  }

  &.visible {
    opacity: 1;
    bottom: 0;
    z-index: 99999;
  }
}
