.root {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;

  & * {
    user-select: none;
  }
}

.close {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 11px;
  right: 22px;
  cursor: pointer;

  path {
    stroke: #c7c7c7;
  }
}

.title {
  color: #000;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 40px;
  text-align: center;
  letter-spacing: -1px;

  @media screen and (max-height: 590px) {
    margin-bottom: 24px;
  }

  & > span {
    letter-spacing: -1px;
    font-size: 22px;
    font-weight: 800;
    color: #ff1694;
  }

  &_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
    margin-bottom: -5px;
  }
}

.packs {
  width: 100%;
  border-radius: 24px;
  background: #ffffff;
  padding: 35px 24px;
  margin-bottom: 10px;

  @media screen and (max-height: 590px) {
    padding: 24px;
  }

  &_list {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }

  &_wrapper {
    height: 80px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (max-height: 590px) {
      margin-bottom: 24px;
    }
  }

  &_item {
    border-radius: 40px;
    height: 80px;
    width: 100%;
    background: #ebe9e9;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 18px;
    position: relative;
    box-shadow: 10px 10px 25px 0 rgb(0 0 0 / 20%),
    -6px -6px 15px 0 rgb(230 230 230 / 30%);

    &_mystery {
      width: 180px;
      justify-content: flex-start;
    }

    .votes {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      color: #ffffff;
      font-weight: 800;
      border-radius: 40px;
      margin-right: 23px;
    }

    .price {
      line-height: 28px;

      & > span {
        font-weight: 600;
        font-size: 28px;
      }

      & .currency {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.8px;
        color: #747474;
      }
    }

    .arrow {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .avatar {
    }

    .size,
    .content,
    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .size {
      padding-bottom: 20px;
      gap: 8px;

      &_top {
        font-size: 22px;
        font-weight: 800;
        line-height: 16px;
      }

      &_bottom {
        font-size: 12px;
        font-weight: 600;
        line-height: 8px;
      }
    }

    .content {
      gap: 2px;

      &_title {
        font-size: 10px;
        font-weight: 600;
        line-height: 8px;
        padding-bottom: 5px;
      }

      &_count {
        font-size: 16px;
        font-weight: 800;
        line-height: 12px;
      }
    }
  }

  .separator {
    font-size: 28px;
    font-weight: 600;
  }

  .mystery {
    display: flex;
    align-items: center;

    &_box {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_s,
      &_m,
      &_l {
        width: 60px;
        height: 60px;
        background-size: cover;
        margin-right: 0;
      }

      &_s {
        background-image: url("./../../assets/images/mysterybox-s.png");
      }

      &_m {
        background-image: url("./../../assets/images/mysterybox-m.png");
      }

      &_l {
        background-image: url("./../../assets/images/mysterybox-l.png");
      }
    }

    &_avatar {
      margin-top: -20px;
    }

    &_counts {
      &_item {
        color: #858585;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 3px;
      }
    }
  }
}

.free {
  width: 100%;
  height: 60px;
  padding: 13px 13px 13px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 2px solid #d7fb73;
  background: transparent;
  color: #000;

  &_plus {
    height: 24px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-align: center;
    color: #000;
    line-height: 15px;
    font-weight: 800;
    margin-right: 24px;
  }

  &_text {
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    text-align: left;
    margin-right: 16px;
  }

  &_timer {
    width: fit-content;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    background: #d7fb73;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -1px;
    padding: 0 10px;

    & * {
      font-weight: 600;
    }
  }
}
