.root {
  color: #fff;
  height: 100%;
  padding-bottom: 80px;

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 10px;
  }

  img {
    max-width: 100%;
    max-height: calc(100vh - 100px);
  }
}
