.main {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.2s ease;
  font-style: italic;
  padding: 8px 16px;
  box-sizing: content-box;
  margin-top: 1px;

  .center {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    text-transform: capitalize;
    max-width: 80%;
    margin-top: 7px;
  }

  .onlineStatus {
    font-size: 11px;
    color: #5dfe35;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 10px;
    gap: 8px;
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left,
  .right {
    width: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & svg {
      filter: drop-shadow(0 0 5px rgb(0, 0, 0));
    }

    &:hover {
      & svg {
        opacity: 0.8;
      }
    }
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
    margin-top: 1px;
  }
}

.header1 {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 16px;
}

.header2 {
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 16px;
}

.header3 {
  display: flex;
  width: 42%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 16px;
}

.header4 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.item1 {
  width: 208px;
  min-height: 60px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  background: #262626;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
  margin-bottom: 6px;
  flex-direction: column;
}

.item2 {
  width: 250px;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  background: #262626;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
  margin-bottom: 6px;
  flex-direction: column;
}

.item3 {
  width: 130px;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  background: #262626;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
  margin-bottom: 6px;
  flex-direction: column;
}

.item4 {
  width: 130px;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  background: #262626;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
  margin-bottom: 6px;
  flex-direction: column;
  margin-right: 16px;
}

.item5 {
  width: 250px;
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  background: #262626;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  user-select: none;
  margin-bottom: 6px;
  flex-direction: column;
  margin-right: 16px;
}

.circle {
  display: flex;
  align-items: center;
  padding: 6px;
}

.time {
  width: 100px;
  margin-left: 16px;
}

.time2 {
  width: 100px;
  margin-right: 16px;
  justify-content: flex-end;
}

.blackOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 99999999;
}

.footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 100%;

  .input_box {
    width: 100%;
    height: 58px;
    border-top: 1px solid #3d3d3f;
    background: #141414;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    display: flex;

    .input {
      width: 82%;
      height: 42px;
      border: 1px solid #3d3d3f;
      border-radius: 25px;
      background: #000;
      margin-left: 15px;
    }

    .button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
}
