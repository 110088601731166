.root {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 15px 10px;

  & * {
    user-select: none;
  }
}

.list {
  width: 100%;
  border-radius: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  &_item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    border-bottom: 1px solid #000;

    & span {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    &:last-child {
      border: none;
    }
  }
}

.button {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #000;

  &.cancel {
    background: #fff;
  }
}
