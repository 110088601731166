.root {
  width: 100%;
  height: 100%;
  background: #000;
}

.area {
  max-height: calc(100% - 140px);

  @media screen and (max-height: 550px) {
    max-height: calc(100% - 125px);
  }
}

.content {
  overflow: hidden;
  padding: 16px 0;
  transition: all 0.3s ease;
}

.media {
  &_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px 16px 24px;
    gap: 10px;
  }

  &_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_item {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    aspect-ratio: 1;
    flex: 1;
    max-width: calc(50% - 10px);
    min-width: 124px;
    border-radius: 16px;

    &_upload {
      background: #474747;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    & img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &_selected {
    position: absolute;
    top: 8px;
    left: 8px;

    & svg {
      filter: drop-shadow(4px 3px 12px rgb(0 0 0 / 0.59));
    }
  }

  &_remove {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    & svg {
      filter: drop-shadow(4px 3px 12px rgb(0 0 0 / 0.59));

      & path {
        fill: #fff;
      }
    }
  }

  &_type {
    position: absolute;
    bottom: 8px;
    left: 8px;

    & svg {
      width: 24px;
      height: 24px;
      filter: drop-shadow(4px 3px 12px rgb(0 0 0 / 0.59));

      & path {
        fill: #fff;
      }
    }
  }

  &_duration {
    position: absolute;
    bottom: 15px;
    right: 8px;
    color: #fff;
    font-size: 12px;
  }
}

.field {
  &_title {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    margin-bottom: 24px;
    color: #fff;
    padding: 0 24px;

    & > span {
      color: #ff4848;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    margin-bottom: 24px;
    padding: 0 24px;

    & > svg {
      margin-right: 8px;
      min-width: 24px;

      & > path {
        fill: #fff;
      }
    }
  }
}

.upload {
  width: calc(100% - 48px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px auto;
  padding: 0 45px;
  border-radius: 30px;
  background: #fff;

  .file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
      color: #000;
      cursor: pointer;

      & > svg {
        margin-right: 8px;
      }
    }
  }

  &_loading {
    background: #747474 !important;
    cursor: auto;

    .file {
      & + label {
        cursor: auto;
        color: #474747;
      }
    }
  }
}

.step {
  height: 32px;
  color: #000;
  background: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 14px;
}
