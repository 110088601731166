body {
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  right: 0;
  margin: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  user-select: none;
  box-sizing: border-box;
  overscroll-behavior-y: contain;
  touch-action: pan-y pan-x pinch-zoom !important;
}

pre {
  word-break: break-all;
  white-space: pre-line;
}

html,
body,
#root {
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: #000;
  overscroll-behavior-y: contain;
}

#root {
  height: 100%;
}

iframe {
  display: none;
}

h1,
h2,
h3,
h4,
p,
span,
em,
b,
button,
input,
label,
button,
form,
div {
  font-family: "Azeret Mono",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  sans-serif !important;
  font-weight: normal;
  font-style: normal;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiBottomNavigationAction-label {
  font-size: 8px !important;
  color: #747474;
  padding-top: 4px;
}

.MuiDrawer-paper {
  background-color: transparent !important;
  box-shadow: none !important;
  max-width: 420px;
  margin: 0 auto;
}

.MuiDrawer-root.MuiDrawer-modal {
  z-index: 9999;
}

.MuiModal-root {
  width: 100%;
}

.MuiCircularProgress-root {
  color: #fff !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.PhotoView-Slider__Counter {
  opacity: 0 !important;
}

.content {
  width: 100%;
  max-width: 420px;
  top: var(--sat);
  margin: 0 auto;
  overflow: hidden;
  left: 0;
  right: 0;
  position: fixed;
}

.contentChatter {
  width: 100%;
  top: var(--sat);
  margin: 0 auto;
  overflow: hidden;
  left: 0;
  right: 0;
  position: fixed;
}

/* Hide dev server overlay */
/*iframe {*/
/*  display: none !important;*/
/*}*/

/*@supports (padding: max(0px)) {*/
/*    .content {*/
/*        height: calc(100% - max(0px, env(safe-area-inset-bottom)));*/
/*    }*/
/*}*/

@media (hover: none) {
  * {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    outline: none;
    user-select: none;
    user-focus: none;
    user-focus-pointer: none;
  }
}

@keyframes loading-skeleton {
  to {
    background-position-x: -20%;
  }
}
