.toast {
  color: #fff;
  background: rgb(21 21 21 / 90%);
  font-size: 14px;
  padding: 16px 70px 16px 45px;
  width: calc(100% - 28px);
  margin: 14px auto;
  border-radius: 8px;
  line-height: 22px;
  overflow: hidden;

  .icon {
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 100;
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    & > div {
      margin: 0 10px 0 0;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 100;
    height: 100%;
    //background: #121212;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &_error {
    background: rgb(238 45 45 / 90%);

    //& .close {
    //    background: #FF4848;
    //}
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: inline-block;
  height: 0;
  overflow: hidden;
  transition: all 500ms cubic-bezier(0.85, 0.13, 0, 1.01);

  &_visible {
    height: fit-content;
  }
}

.list {
  padding-left: 24px;
}

.button {
  background-color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: 800;
  margin-top: 16px;

  &_outline {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
  }
}
