$primary: #ffffff;
$secondary: #d7fb73;
$error: #ff4848;

.input {
  position: relative;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;

  label {
    position: absolute;
    top: 5px;
    left: 24px;
    transition: none;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    line-height: 16px;
    color: $primary;
    cursor: text;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    background-color: transparent;
    font-size: 16px;
    box-sizing: border-box;
    padding: 20.2px 24px 9px 24px;
    color: $primary;
    border: 1px solid #747474;
    outline: none;
    border-radius: 25px;
    caret-color: $primary;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  textarea {
    width: 100%;
    background-color: transparent;
    resize: none;
    min-height: 100px;
    font-size: 16px;
    outline: none;
    box-sizing: border-box;
    padding: 23.2px 24px 16px 24px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 16px;
  }

  &.textarea {
    align-items: flex-start;
    height: fit-content;
  }

  .label_background {
    position: absolute;
    background: #000;
    width: calc(100% - 2px);
    height: 25px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 0;
    top: 1px;
    left: 1px;
    right: 1px;
  }

  &.password {
    input {
      width: 100%;
      padding-right: 50px;
    }

    .visibility {
      position: absolute;
      right: 24px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &.focused {
    input,
    textarea {
      border-color: $secondary;
    }
  }

  &.white {
    &.focused {
      input,
      textarea {
        border-color: #ffffff;
      }
    }
  }

  &.error {
    input {
      border-color: $error;
    }

    label {
      color: $error;
    }
  }

  .character_count {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    line-height: 16px;
    color: $primary;
    cursor: text;
    z-index: 1;
    margin-left: 4px;
  }
}
