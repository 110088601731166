.root {
}

.menu {
  &_create {
    & span {
      color: #d7fb73;
    }
  }

  &_icon {
    path {
      transition: all 0.2s ease;
    }

    &_active {
      path {
        fill: #fff;
        stroke: #fff;
      }
    }

    &:hover {
      path {
        stroke: #fff;
      }
    }

    &_create:hover {
      path {
        stroke: #000000;
      }
    }
  }

  &_label_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 104px;
    white-space: nowrap;

    &_text {
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 600;
    }

    &_text_active {
      color: #fff;
    }

    &_badge {
      border: 0 solid transparent;
      padding: 0 4px;
      background: #FF1694;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
    }
  }
}
