.root {
  background-color: #000;
  height: calc((var(--vh, 1vh) * 100) - var(--sab, 0) - var(--sat, 0));
  min-height: calc((var(--vh, 1vh) * 100) - var(--sab, 0) - var(--sat, 0));
  top: 0; //var(--sat, 0);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 16px;

  & * {
    user-select: none;
  }
}

.header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  z-index: 100;

  @media screen and (max-height: 650px) {
    height: 50px;
  }

  .close {
    width: 50px;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-style: italic;
    color: #fff;
  }

  .save {
    width: 50px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 800;
    line-height: 24px;
    color: #4285f4;
  }
}

.preview {
  width: 100%;
  height: calc(100% - 190px);
  overflow: hidden;
  border-radius: 24px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 600px) {
    height: calc(100% - 180px);
  }

  img {
    width: 100%;
  }

  &.video {
    height: 100%;
    position: relative;
    margin-top: -60px;
  }
}

.input {
  z-index: 100;
}

.save_button {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 36px;
  background: #d7fb73;
  margin: 0 auto;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;

  @media screen and (max-height: 600px) {
    height: 40px;
  }
}

.field {
  margin: 10px 0;
  height: 50px;

  @media screen and (max-height: 600px) {
    height: 40px;
  }

  & > input {
    height: 50px;

    @media screen and (max-height: 600px) {
      height: 40px;
    }
  }
}
