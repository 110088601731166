.root {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.content {
  width: 100%;
  max-width: 340px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &_icon {
    margin-bottom: 32px;
  }

  &_message {
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
  }
}
