.selectCountryWrapper {
  width: 100%;

  & h2 {
    padding-top: 68px;
    font-weight: 800;
    font-size: 22px;
    text-align: center;
    margin-top: 0;
  }

  & h3 {
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
  }

  .autocompleteWrapper {
    margin-top: 40px;
  }

  .buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
    padding-bottom: 54px;
  }

  .buttonText {
    font-weight: 800;
    color: #fff;
  }
}
