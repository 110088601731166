.root {
  width: 420px;
  display: flex;
  align-items: center;
  height: 60px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)),
  linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
  border: 1px solid #ffffff29;
  transition: all 500ms cubic-bezier(0.85, 0.13, 0, 1.01);

  &_hidden {
    animation-name: hide;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 420px) {
  .root {
    width: 100vw;
  }
}

.text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  line-height: 14px;
  margin-left: 32px;

  u {
    color: #4285f4;
    font-weight: 600;
  }
}

.close {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 32px;
  padding-left: 10px;
}

@keyframes hide {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(1000px);
    height: 0;
  }
}
