.challenge {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.8px;

  svg {
    //opacity: 0.8;
    opacity: 1;
  }

  path {
    fill: #fff;
  }

  &.days_low,
  &.times_low {
    padding: 1px 10px 1px 8px;
    width: fit-content;
    height: 20px;
    border-radius: 10px;

    svg {
      opacity: 1;
    }

    path {
      fill: #000;
    }
  }

  &.days_low {
    background: #fef035;
    color: #000;

    svg {
      opacity: 1;
    }

    path {
      fill: #000;
    }
  }

  &.dark {
    path {
      fill: #000;
    }

    & .timer {
      color: #000;
    }
  }

  &.times_low {
    background: linear-gradient(
      116.62deg,
      #ff00d6 -30.24%,
      #ff00d6 2.55%,
      #ff007a 27.91%,
      #ff8a00 89.66%,
      #ff007a 120.72%
    );
    color: #fff !important;

    svg {
      opacity: 1;
    }

    path {
      fill: #fff !important;
    }

    & .timer {
      color: #fff !important;
    }
  }
}

.feed {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  & .top {
    line-height: 18px;
    color: #d7fb73;
    font-weight: 800;

    & * {
      line-height: 18px;
      color: #d7fb73;
      font-weight: 800;
    }
  }

  & .bottom {
    font-weight: 400;
    color: #d7fb73;

    & * {
      font-weight: 400;
      color: #d7fb73;
    }
  }

  &.days_low {
    & .top {
      color: #fef035;

      & * {
        color: #fef035;
      }
    }

    & .bottom {
      color: #fef035;

      & * {
        color: #fef035;
      }
    }
  }

  &.times_low {
    & .top {
      color: #ff01a4;

      & * {
        color: #ff01a4;
      }
    }

    & .bottom {
      color: #ff01a4;

      & * {
        color: #ff01a4;
      }
    }
  }
}
