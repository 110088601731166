.root {
  position: absolute;
  width: 100%;
  bottom: 67px;
  display: flex;
  flex-direction: column;
  z-index: 12;
  pointer-events: none;
}

.row {
  padding: 0 8px;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
}

.col {
  padding-right: 16px;
}

.info {
  min-width: 60px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 46px;
  padding: 0 4px;
  flex-direction: column;
  pointer-events: auto;

  &_item {
    min-width: 60px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 14px;

    & * {
      will-change: transform;
      transform: translateZ(0);
    }

    &_user {
      min-height: 40px;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      position: relative;
      cursor: pointer;

      &_subscribe {
        position: absolute !important;
        top: 26px;
        left: 0;
        right: 0;
      }

      &_subscribed_success {
        position: absolute;
        top: 26px;
      }
    }

    &_mute {
      pointer-events: auto;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_share,
    &_comments {
      cursor: pointer;
    }

    &_comments {
      & > div {
        &:first-child {
          margin-bottom: -12px;
        }
      }
    }

    &_share {
      & > div {
        &:first-child {
          margin-bottom: -4px;
        }
      }
    }
  }

  &_bottom {
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    line-height: 18px;
    letter-spacing: -2px;
    text-shadow: 4px 3px 12px #00000059;
    pointer-events: auto;
  }
}

.description {
  & * {
    will-change: transform;
    transform: translateZ(0);
  }

  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-shadow: 0px 1px 15px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-bottom: 10px;
  pointer-events: auto;

  &.expanded > div {
    &:after {
      content: "hide";
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &.clamped > div {
    &:after {
      content: "more...";
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

.vote {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  text-transform: uppercase;

  &_free {
    width: 100%;
    height: 54px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      106.9deg,
      #ff00d6 -17.57%,
      #ff00d6 4.77%,
      #ff00c7 19.9%,
      #ff007a 52.61%,
      #ff007a 93.65%
    );
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    cursor: pointer;
    pointer-events: auto;
  }

  &_free_lock,
  &_buy {
    width: fit-content;
    height: 54px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    pointer-events: auto;
  }

  &_free_lock {
    background: #fff;
    color: #000;
    margin-right: 8px;
    font-weight: 600;
    font-size: 12px;
    padding: 0 16px;
    cursor: auto;

    & .timer {
      width: fit-content;
      height: 16px;
      display: flex;
      align-items: center;
      border-radius: 28px;
      background: #000;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: -1px;
      padding: 0 8px;
      margin-left: 16px;

      & * {
        font-weight: 600;
      }
    }
  }

  &_buy {
    flex: 1;
    background: linear-gradient(
      106.9deg,
      #ff00d6 -17.57%,
      #ff00d6 4.77%,
      #ff00c7 19.9%,
      #ff007a 52.61%,
      #ff007a 93.65%
    );
    color: #fff;
    font-weight: 800;
    font-size: 18px;
  }

  & * {
    will-change: transform;
    transform: translateZ(0);
  }
}
